import React, { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { Material } from '../../http/Material';
import MaterialCard from './MaterialCard';

const MaterialsList: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<Material[]>("/items/materials?fields=*,file.*");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && response.length) {
    return (
      <div className="mt80">
        <h2 className="title is-2">Reports &amp; books</h2>
        <div className="columns is-multiline mt16">
          { response.map(material => <MaterialCard key={material.id} material={material} />) }
        </div>
      </div>
    )
  }

  return <p></p>
}

export default MaterialsList;