import { File } from '../http/File';

type imageSize = 'small' | 'medium' | 'large';
type imageCrop = 'contain' | 'crop';

export function getThumbnail(image: File): string {
  const pic = image.data.thumbnails.find(thumbnail => thumbnail.url.includes('?key=thumbnail'));
  if (pic) {
    return pic.url;
  }

  return image.data.full_url;
}

export function getThumbnailWithSize(image: File, size: imageSize, crop: imageCrop): string {
  const pic = image.data.thumbnails.find(thumbnail => thumbnail.url.includes(`?key=directus-${size}-${crop}`));
  if (pic) {
    return pic.url;
  }

  return image.data.full_url;
}