import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const TopBar: React.FC = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <div className="container">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <NavLink className="navbar-item" to="/">
            <img src="/img/logo.svg" alt="FTSF logo"/>
          </NavLink>
          <button className={`navbar-burger burger ${isMenuActive ? 'is-active' : ''}`} onClick={() => setIsMenuActive(!isMenuActive)} aria-label="menu" aria-expanded="false" data-target="navMenu">
            <span aria-hidden="true"></span><span aria-hidden="true"></span><span aria-hidden="true"></span>
          </button>
        </div>
        <div className={`navbar-menu ${isMenuActive ? 'is-active' : ''}`} id="navMenu">
          <div className="navbar-end">
            <NavLink onClick={() => setIsMenuActive(false)} className="navbar-item" to="/what-we-do">What we do</NavLink>
            <NavLink onClick={() => setIsMenuActive(false)} className="navbar-item" to="/our-work">Our work</NavLink>
            <NavLink onClick={() => setIsMenuActive(false)} className="navbar-item" to="/members">Members</NavLink>
            <NavLink onClick={() => setIsMenuActive(false)} className="navbar-item" to="/research">Research</NavLink>
            <NavLink onClick={() => setIsMenuActive(false)} className="navbar-item" to="/about-us">About us</NavLink>
            <NavLink onClick={() => setIsMenuActive(false)} className="navbar-item" to="/contact-us">Contact</NavLink>
            <div className="navbar-item">
              <div className="buttons">
                <NavLink onClick={() => setIsMenuActive(false)} className="button is-primary" to="/join-us">Join us</NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default TopBar;