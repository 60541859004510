import React from 'react';
import { Link } from 'react-router-dom';

const Benefits: React.FC = () => {
  return (
    <section className="section has-background-blue50">
      <div className="container">
        <div className="has-text-centered">
          <h5 className="title is-5 has-text-primary is-uppercase">Mutual advantage</h5>
        </div>
        <div className="has-text-centered mt16">
          <h2 className="title is-2">Explore the benefits of joining</h2>
        </div>
        <div className="columns is-multiline mt40">
          <div className="column is-hidden-tablet">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">With you, we can make a change</h4>
              <p>Get access to our network of members in Africa and Europe and work together to support the growth of local technological solutions in africa</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/join-us">More benefits</Link>
            </div>
          </div>
          <div className="column is-one-third is-hidden-mobile">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">Individuals</h4>
              <p>You can be part of a group of like-minded people that use technology for positive change in developing countries - use your skills to make the world better.</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/join-us/individual">More benefits</Link>
            </div>
          </div>
          <div className="column is-one-third is-hidden-mobile">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">Universities/Academia</h4>
              <p>Opportunities for interesting research on many subjects including Economics, Ethics, Sustainability and ICT4D - attract students and funding.</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/research">More benefits</Link>
            </div>
          </div>
          <div className="column is-one-third is-hidden-mobile">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">European ICT companies</h4>
              <p>Participating in ICT projects in developing countries can bring unexpected benefits - positive PR, team building, enhanced recruitment and staff retention.</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/join-us/ict-europe">More benefits</Link>
            </div>
          </div>
          <div className="column is-one-third is-hidden-mobile">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">African ICT companies</h4>
              <p>Gain technical and business skills and learn the lastest techniques from expereinced mentors in top companies across the European Union.</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/join-us/ict-africa">More benefits</Link>
            </div>
          </div>
          <div className="column is-one-third is-hidden-mobile">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">Funding Organisations, NGOs<br/>and Development Agencies</h4>
              <p>Support the growth of the African tech sector and unlock the potential of ICTs to enable other sectors.</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/join-us/ngo">More benefits</Link>
            </div>
          </div>
          <div className="column is-one-third is-hidden-mobile">
            <div className="box is-relative" style={{minHeight:"280px", paddingBottom: "80px"}}>
              <h4 className="title is-4">Organisations with a need for ICT services in Africa</h4>
              <p>Find partners who have the right domain expertise to build ICT tools for use on the African continent.</p>
              <Link className="button is-primary" style={{position: "absolute", bottom: "20px"}} to="/join-us/need-ngo">More benefits</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits;