import React from 'react';
import { Link } from 'react-router-dom';

type ReadStoryProps = {
  url: string;
}

const ReadStory: React.FC<ReadStoryProps> = ({url}) => {
  return (
    <Link className="is-block mt24" to={url}>
      <span>Read story</span>
      <span className="icon is-small pl8">
        <i className="fas fa-arrow-right"></i>
      </span>
    </Link>
  );
}

export default ReadStory;