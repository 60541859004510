import React from 'react';
import { Material } from '../../http/Material';
import { Link } from 'react-router-dom';

type MaterialCardProps = {
  material: Material;
}

const MaterialCard: React.FC<MaterialCardProps> = ({material}) => {
  return (
    <div className="column is-4 mt24">
      <div className="has-background-light-green p32">
        <h4 className="subtitle is-4">
          { material.title }
        </h4>
        { material.file && material.file.data && (
          <a className="button is-primary" href={material.file.data.full_url} download>
            Download free PDF (205 KB)
          </a>
        )}

        { !material.file && (
          <Link to="/contact-us" className="button">Contact us to request this material</Link> 
        )}
      </div>
    </div>
  )
}

export default MaterialCard;