import React, { Fragment } from 'react';
import Benefits from '../components/Benefits';
import MemberActivitiesList from '../components/MemberActitivities/MemberActivitiesList';
import {Link} from "react-router-dom";

const JoinUs: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Help building innovative solutions for developing countries</h1>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2 has-text-centered">
              <a href="https://docs.google.com/forms/d/1U9n_ZxAe3sQjCsbO0iau70vT4IAMZ86LUzaL9IKl2kU/edit" className="button is-primary mr40" target="_blank">Join as an organization</a>
              <a href="https://docs.google.com/forms/d/1yOzauUKJyv-UzlxlKuIJCwIjkl5G3_vRt167HQaOA90/edit" className="button is-primary ml40" target="_blank">Join as an individual</a>
            </div>
          </div>
          <div className="columns mt80">
            <div className="column is-8 is-offset-2 has-text-centered">
              <p>The Fair Trade Software Foundation acts as a bridge to connect individuals and companies located in Europe with counterparts in the developing world. Join us in exchanging skills and reaping mutual benefit.</p>
            </div>
          </div>
          <div className="columns mt80">
            <MemberActivitiesList />
          </div>
        </div>
      </section>
      <Benefits />
    </Fragment>
  );
}

export default JoinUs;