import React from 'react';
import { Activity } from '../../http/Activity';
import './ActivityCard.sass';

type ActivityCardProps = {
  activity: Activity;
}

const ActivityCard: React.FC<ActivityCardProps> = ({activity}) => {
  return (
    <div className="columns activity-card">
      <div className="column is-3 is-offset-2 has-text-centered">
        <img alt={activity.title} className="is-inline-block" src={activity.icon.data.full_url}/>
      </div>
      <div className="column is-5">
        <h3 className="title is-3">
          {activity.title}
        </h3>
        <p>
          {activity.description}
        </p>
      </div>
    </div>
  )
}

export default ActivityCard;