import React from 'react';
import ReadStory from '../ReadStory';
import { Story } from '../../http/Story';
import { Link } from 'react-router-dom';

type StoryPreviewProps = {
  story: Story;
}

const StoryPreview: React.FC<StoryPreviewProps> = ({ story }) => {
  const storyUrl = `/our-work/${story.slug}`;

  return (
    <div className="column is-6">
      <Link to={storyUrl}>
        <img alt={story.title} src={story.image.data.full_url}/>
      </Link>
      <h4 className="title is-4 mt24">
        {story.title}
      </h4>
      <p>
        {story.introduction}  
      </p>
      <ReadStory url={storyUrl} />
    </div>
  )
}

export default StoryPreview;