import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './components/Routes';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TopBar />
      <Routes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
