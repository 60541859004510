import React, { Fragment } from 'react';
import JoinUsForm from '../../components/JoinUsForm/Form';

const Individual: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium" style={{backgroundImage: 'url(/img/join-us/individual-hero.jpg)', backgroundSize: 'cover', backgroundPosition: "center"}}>
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white">Participate in a good cause</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">&nbsp;</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">Get involved</h2>
          </div>
          <div className="columns mt40">
            <div className="column is-8 is-offset-2 has-text-centered">
              <p>The market for ICT services in developing countries is growing fast with demand from government organisations, NGOs and many sectors of economic activity. The capacity for ICT to create jobs and transform services such as Healthcare or Finance is widely recognised, and it is clear that digital technologies have the potential to make a significant positive impact on society.</p>
              <p className="mt24">FTSF acts as a bridge to connect  ICT companies located in Africa with counterparts in Europe as a means to building international networks, exchanging skills and sharing knowledge. Membership of FTSF is free and is open.</p>
            </div>
          </div>
          <div className="mt80">
            <div className="has-text-centered">
              <h5 className="title is-5 has-text-primary is-uppercase">Benefits</h5>
            </div>
            <div className="has-text-centered mt16">
              <h2 className="title is-2">See what the foundation can mean for your company</h2>
            </div>
          </div>
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="columns">
                <div className="column">
                  <div className="mt48">
                    <div className="has-text-centered"><img alt="" src="/img/icons/network.svg"/>
                      <h6 className="subtitle is-6 mt24">Increase your network</h6>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="mt48">
                    <div className="has-text-centered"><img alt="" src="/img/icons/equality.svg"/>
                      <h6 className="subtitle is-6 mt24">Be part of a group of like-minded people</h6>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="mt48">
                    <div className="has-text-centered"><img alt="" src="/img/icons/engage.svg"/>
                      <h6 className="subtitle is-6 mt24">Help a good cause</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-blue50">
        <div className="container">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">Become a member</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">Join the Fair Trade Software Foundation</h2>
          </div>
          <div className="columns mt40">
            <div className="column is-6 is-offset-3">
              <JoinUsForm isCompany={false} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Individual;