import React from 'react';
import { Person } from '../../http/Person';

type PersonCardProps = {
  person: Person;
}

const PersonCard: React.FC<PersonCardProps> = ({person}) => {
  return (
    <div className="board-card mt40">
      <div className="avatar" style={{backgroundImage: `url(${person.photo.data.full_url})`}}>
        <a className="link icon is-size-4" href={`https://www.linkedin.com/in/${person.linkedin_username}/`} target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin-in">
        </i></a>
      </div>
      <h5 className="title is-5 mt24">
        {person.name}
      </h5>
    </div>
  )
}

export default PersonCard;