import React, { Fragment } from 'react';
import Feed from '../components/Stories/Feed';
import FeaturedStory from '../components/Stories/Featured';

const OurWork: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Our work</h1>
          <h3 className="subtitle is-3 has-text-white mt32">Projects that deliver real impact</h3>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <FeaturedStory />
        </div>
      </section>
      <section className="section has-background-blue50">
        <Feed />
      </section>
    </Fragment>
  );
}

export default OurWork;