import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import useFetch from '../../hooks/useFetch';
import { Article } from '../../http/Article';
import { Link } from 'react-router-dom';

const HomeArticle: React.FC = () => {
  const slug = "home"
  const [{response, isLoading, error}, doFetch] = useFetch<Article[]>(
    `/items/articles?fields=*,image.data&filter[slug]=${slug}`
  );

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  return (
    <section className="section is-medium">
      <div className="container">
        { isLoading && <p>Loading...</p> }
        { error && <p>Loading error article {slug}</p> }

        { response && response.length && (
          <div className="columns">
            <div className="column is-5">
              <h1 className="title is-1 is-spaced">{ ReactHtmlParser(response[0].subtitle) }</h1>
              <h6 className="subtitle is-6">{ ReactHtmlParser(response[0].content) }</h6>
              <Link className="button is-primary mt40" to="/what-we-do">Discover the Foundation</Link>
            </div>
            { !!response[0].image && (
              <div className="column is-offset-1">
                <img src={response[0].image.data.full_url} alt="Collaborate with FTSF" style={{height: "330px"}}/>
              </div>
            )}
          </div>
        )}
        { (!response || !response.length) && <p>Article {slug} not found</p>}
      </div>
    </section>
  )
}

export default HomeArticle;