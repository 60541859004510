import React from 'react';

const ErrorMessage: React.FC = () => {
  return (
    <div className="mt40 has-text-centered">
      <p>Something went wrong</p>
      <p className="mt24">Unfortunately, we were not able to receive your details. Please, contact us directly via email or phone.</p>
    </div>
  )
}

export default ErrorMessage;