import React, { Fragment, useEffect } from "react"
import ReactHtmlParser from 'react-html-parser';
import useFetch from "../../hooks/useFetch";
import { Article } from "../../http/Article";

const WhatWeCanOfferArticle: React.FC = () => {
  const slug = "what-we-can-offer"
  const [{response, isLoading, error}, doFetch] = useFetch<Article[]>(
    `/items/articles?fields=*,image.data&filter[slug]=${slug}`
  );

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error article {slug}</p>
  }

  if (response && response.length) {
    return (
      <Fragment>
        <div className="column is-5">
          <img alt={response[0].title} src={response[0].image?.data?.full_url}/>
        </div>
        <div className="column is-6 is-offset-1 content">
          <h3 className="title is-3">
            { response[0].title }
          </h3>

          { ReactHtmlParser(response[0].content) }
        </div>
      </Fragment>
    )
  }

  return <p>Article {slug} not found</p>
}

export default WhatWeCanOfferArticle;