import React, { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import WhatWeCanOfferArticle from '../components/Article/WhatWeCanOffer';
import JoinUsForm from '../components/JoinUsForm/Form';
import MaterialsList from '../components/Materials/MaterialsList';

const Research: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Research</h1>
          <h3 className="subtitle is-3 has-text-white mt32">Discover the challenges and opportunities of Fair Trade Software</h3>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">&nbsp;</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">Help us with research</h2>
          </div>
          <div className="columns mt40">
            <div className="column is-8 is-offset-2 has-text-centered">
              <p>Research helps us giving insight into how Fair Trade software is performing agianst its Theory of Change. They also enable us to identify and understand areas of challenge and opportunities where we can take actions to improve our effectiveness. Fair Trade Software follows best practice in an open and transparent manner to support wider knowledge and understanding of the Fair Trade Software impacts.</p>
              <HashLink smooth className="button is-primary mt32" to="/research#form-section">Become a research member</HashLink>
            </div>
          </div>
          <div className="columns mt80">
            <WhatWeCanOfferArticle />
          </div>
          <MaterialsList />
        </div>
      </section>
      <section className="section is-medium has-background-blue50" id="form-section">
        <div className="container">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">Become a research member</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">Join the Fair Trade Software Foundation</h2>
          </div>
          <div className="columns mt56">
            <div className="column is-8 is-offset-2">
              <div className="columns">
                <div className="column is-5">
                  <div className="pr24">
                    <div className="has-text-centered"><img alt="" src="/img/icons/network.svg"/>
                      <h6 className="subtitle is-6 mt24">Researh a topic that helps developing countries</h6>
                    </div>
                    <div className="mt80">
                      <div className="has-text-centered"><img alt="" src="/img/icons/mentorship.svg"/>
                        <h6 className="subtitle is-6 mt24">Help us improve our effectiveness</h6>
                      </div>
                    </div>
                    <div className="mt80">
                      <div className="has-text-centered"><img alt="" src="/img/icons/investment.svg"/>
                        <h6 className="subtitle is-6 mt24">Access to a network of credible members</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-7">
                  <JoinUsForm isCompany={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Research;