import React, { useEffect, Fragment } from 'react';
import useFetch from '../../hooks/useFetch';
import { Activity } from '../../http/Activity';
import ActivityCard from './ActivityCard';

const MemberActivitiesList: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<Activity[]>("/items/activities?fields=*,icon.data&filter[type]=join-us");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && response.length) {
    return (
      <Fragment>
        { response.map(activity => <ActivityCard key={activity.id} activity={activity} />) }
      </Fragment>
    )
  }

  return <p>No activity items</p>
}

export default MemberActivitiesList;