import React, { useEffect } from 'react';
import ReadStory from '../ReadStory';
import useFetch from '../../hooks/useFetch';
import { Story } from '../../http/Story';

const FeaturedStory: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<Story[]>("/items/stories?fields=*,image.data&filter[featured]=1");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && !!response.length) {
    return (
      <div className="columns is-vcentered mt40">
        <div className="column is-7">
          <img src={response[0].image.data.full_url} alt={response[0].title}/>
        </div>
        <div className="column">
          <h3 className="title is-3">
            { response[0].title }
          </h3>
          <p>
            { response[0].introduction }
          </p>
          <ReadStory url={`/our-work/${response[0].slug}`} />
        </div>
      </div>
    )
  }

  return <p>&nbsp;</p>
}

export default FeaturedStory;