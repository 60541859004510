import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import GoalsList from '../components/SDGoals/GoalsList';
import OurActivityList from '../components/OurActivities/OurActivityList';
import ArticleCard from '../components/Article/ArticleCard';
import WhatWeDoArticle from '../components/Article/WhatWeDoArticle';

const WhatWeDo: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium" style={{backgroundImage: "url(/img/what-we-do/hero.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}>
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Improving economic prospects</h1>
          <h3 className="subtitle is-3 has-text-white mt32">Enhancing employability &amp; economic inclusion</h3>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <WhatWeDoArticle />
        </div>
      </section>
      <section className="section is-medium has-background-blue50">
        <div className="container">
          <OurActivityList />
        </div>
      </section>
      <section className="section">
        <div className="container">
          <ArticleCard slug="our-commitment" />
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <GoalsList />
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-blue50 is-medium">
        <div className="container has-text-centered">
          <h2 className="title is-2">Get to know us</h2>
          <p>Learn more about the FTS Foundation history, Fair Trade Principles and the Board.</p>
          <Link className="button is-primary mt40" to="/about-us">Learn more</Link>
        </div>
      </section>
    </Fragment>
  );
}

export default WhatWeDo;