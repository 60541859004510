import React, { Fragment } from 'react';
import ContactUsForm from '../components/ContactUsForm/Form';

const ContactUs: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Contact us</h1>
          <h3 className="subtitle is-3 has-text-white mt32">Get in touch with us. We'd love to hear from you.</h3>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="columns has-text-centered">
                <div className="column">
                  <div className="mt40"><img alt="" src="/img/icons/letter.svg"/>
                    <p className="mt24">E-mail: <a href="mailto:info@ftsf.eu">info@ftsf.eu</a></p>
                  </div>
                </div>
                <div className="column">
                  <div className="mt40"><img alt="" src="/img/icons/phone.svg"/>
                    <p className="mt24">
                      Tel: +31 (0)70 42 77 552 <br/>
                      Fax: +31 (0)70 42 77 554
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div className="mt40"><img alt="" src="/img/icons/office.svg"/>
                    <p className="mt24 has-text-weight-bold">Appointments Only</p>
                    <p className="mt12">Noordeinde 45a, 2514 GC The Hague <br/>The Netherlands</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-blue50">
        <div className="container">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">Send a direct message</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">Fill the contact form in</h2>
          </div>
          <div className="columns mt40">
            <div className="column is-6 is-offset-3">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ContactUs;