import React from 'react';

const NotFound: React.FC = () => {
  return (
    <section className="section is-large">
      <div className="container has-text-centered">
        <h1 className="title is-1 is-spaced">Page not found</h1>
        <h3 className="subtitle is-3 mt32">Page you're trying to open does not exist. Try to open home page again.</h3>
      </div>
    </section>
  )
}

export default NotFound;