import React from 'react';
import { Fragment } from "react"

const Policy: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Fair Trade Software Foundation Policies</h1>
          <h3 className="subtitle is-3 has-text-white mt32">We value your privacy</h3>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <h5 className="title is-5">General brand usage guidelines</h5>
              <p className="mt24">Fair Trade Software is not required to be the core business of Foundation members, however the use of the FTS logo on non-FTS products is prohibited. Any project claiming to be Fair Trade Software must relate to work conducted in a way that adheres to the accepted definitions of Fair Trade and Fair Trade Principles, and the additional FTS Foundation Principles as outlined on this website. Promotional material related to Fair Trade Software must be clear, related to specific projects that are demonstrably FTS, and not misleading in any way. All Foundation members must actively promote Fair Trade Software.</p>
              <p className="mt24">Failure to comply with the Ongoing Requirements may result in immediate termination of membership and forfeit of any sponsorship.</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default Policy;