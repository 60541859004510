import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import MembersList from '../components/Members/MembersList';
import MemberActivitiesList from '../components/MemberActitivities/MemberActivitiesList';

const Members: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Together we can make an impact on the world</h1>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2 has-text-centered">
              <h2 className="title is-2">Interesting in getting involved?</h2>
              <p className="mt40">The Fair Trade Software Foundation acts as a bridge to connect individuals and companies located in Europe with counterparts in the developing world. Join us in exchanging skills and reaping mutual benefit.</p>
              <Link className="button is-primary mt24" to="/join-us">Become a member</Link>
            </div>
          </div>
          <div className="columns mt80">
            <MemberActivitiesList />
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-blue50">
        <div className="container has-text-centered">
          <h2 className="title is-2">Our members</h2>
          <p className="mt40">As an official member, you’ll have access to our network, reporting and proof for all projects funded.</p>
          <div className="columns mt80">
            <div className="column is-10 is-offset-1">
              <MembersList />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Members;