import React from 'react';
import { SDGoal } from '../../http/SDGoal';

type GoalBadgeProps = {
  goal: SDGoal;
}

const GoalBadge: React.FC<GoalBadgeProps> = ({goal}) => {
  return (
    <span className="tag is-size-6 is-SDG" style={{backgroundColor: goal.color}}>
      {goal.title}
    </span>
  )
}

export default GoalBadge;