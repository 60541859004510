import React, { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { Story } from '../../http/Story';
import StoryPreview from './Preview';

const Feed: React.FC = () => {
  // Pagination feature is commented yet
  // const limit = 6;
  // const page = 0;
  const [{response, isLoading, error}, doFetch] = useFetch<Story[]>("/items/stories?fields=*,image.data&filter[featured]=0");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return (
      <div className="container">
        <p>Loading....</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="container">
        <p>There was an error while loading stories</p>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="columns is-multiline is-variable is-8">
        { 
          response &&
          response.map(story => <StoryPreview key={story.slug} story={story}/>)
        }
      </div>
      {/* { 
        response &&
        (limit < response.length) &&
        (
          <div className="has-text-centered mt48">
            <p className="has-text-weight-bold">6 of {response.length} stories loaded</p>
            <button className="button is-primary mt16">Load more stories</button>
          </div>
        )  
      } */}
    </div>
  )
}

export default Feed;