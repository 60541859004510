import React, { useEffect, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 
import useFetch from '../hooks/useFetch';
import { Story } from '../http/Story';
import NotFound from './NotFound';
import GoalBadge from '../components/SDGoals/GoalBadge';

interface SingleStoryProps extends RouteComponentProps<{
  slug: string;
}> { }

const SingleStory: React.FC<SingleStoryProps> = ({ match }) => {
  const slug = match.params.slug;
  const [{response, isLoading, error}, doFetch] = useFetch<Story[]>(
    `/items/stories?fields=*,image.data,development_goals.development_goal.*&filter[slug]=${slug}`
  );

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return (
      <section className="section">
        <div className="container">
          Story is loading...
        </div>
      </section>
    )
  }

  if (error) {
    return (
      <section className="section">
        <div className="container">
          There was an error while loading a story.
        </div>
      </section>
    )
  }

  if (response && !!response.length) {
    return (
      <section className="section">
        <div className="container">
          <Link className="is-block mt24" to="/our-work">
            <span className="icon is-small"><i className="fas fa-arrow-left"></i></span><span className="pl8">Back to stories</span>
          </Link>
          <div className="columns">
            <div className="column is-6">
              <h2 className="title is-2 mt16 is-spaced">
                { response[0].title }
              </h2>
              <h5 className="subtitle is-5 mt40">
                { response[0].introduction }
              </h5>
              <div className="mt48">
                { ReactHtmlParser(response[0].content) }
              </div>
            </div>
            <div className="column is-5 is-offset-1">
              <img alt={response[0].title} src={response[0].image.data.full_url}/>

              { !!response[0].development_goals.length && (
                <Fragment>
                  <p className="is-size-7 has-text-weight-medium mt32">SDGS</p>
                  <div className="tags mt12">
                    { response[0].development_goals.map((goalWrapper) => <GoalBadge key={goalWrapper.development_goal.id} goal={goalWrapper.development_goal} />) }
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }

  return <NotFound />
}

export default SingleStory;