import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns is-mobile is-multiline is-centered">
          <div className="column is-full-mobile">
            <div className="title">
              <img src="/img/logo.svg" style={{maxHeight: "2.5rem"}} alt="FTSF logo"/>
            </div>
            <p><a href="mailto:info@ftsf.eu">info@ftsf.eu</a></p>
            <p>tel. +31 (0)70 42 77 552</p>
            <p>fax. +31 (0)70 42 77 554</p>
          </div>
          <div className="column is-half-mobile">
            <h6 className="title is-6 has-text-primary is-uppercase">Get to know us</h6>
            <NavLink className="grey-link is-block" to="/what-we-do">What we do</NavLink>
            <NavLink className="grey-link is-block" to="/our-work">Our work</NavLink>
            <NavLink className="grey-link is-block" to="/research">Research</NavLink>
            <NavLink className="grey-link is-block" to="/about-us">About us</NavLink>
          </div>
          <div className="column is-half-mobile">
            <h6 className="title is-6 has-text-primary is-uppercase">Connect</h6>
            <NavLink className="grey-link is-block" to="/members">Members</NavLink>
            <NavLink className="grey-link is-block" to="/join-us">Join us</NavLink>
            <NavLink className="grey-link is-block" to="/contact-us">Contact</NavLink>
          </div>
          <div className="column is-narrow has-text-centered">
            <h6 className="title is-6 has-text-primary is-uppercase">Find us on</h6>
            <a className="black-link" href="https://www.facebook.com/infoFTSF/" target="_blank" rel="noopener noreferrer">
              <span className="icon is-size-4 mr16"><i className="fab fa-facebook-f"></i></span>
            </a>
            <a className="black-link" href="https://twitter.com/infoFTSF" target="_blank" rel="noopener noreferrer">
              <span className="icon is-size-4 mr16"><i className="fab fa-twitter"></i></span>
            </a>
            <a className="black-link" href="https://www.linkedin.com/company/fair-trade-software-foundation" target="_blank" rel="noopener noreferrer">
              <span className="icon is-size-4"><i className="fab fa-linkedin-in"></i></span>
            </a>
          </div>
        </div>
        <hr/>
        <div className="columns">
          <div className="column">
            <p className="has-text-weight-light is-7 has-text-centered-mobile">
              © 2020 Fair Trade Software Foundation.
               <Link className="is-block-mobile black-link has-text-weight-bold" to="/policy">Foundation policies</Link>
            </p>
          </div>
          <div className="column">
            <p className="has-text-right has-text-centered-mobile"><span className="icon has-text-link pr8"><i className="fas fa-location-arrow"></i></span>Noordeinde 45a, 2514 GC The Hague (NL)</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;