import React from 'react';
import { Member } from '../../http/Member';
import { getThumbnailWithSize } from '../../utils/image.utils';

type MemberCardProps = {
  member: Member;
}

const MemberCard: React.FC<MemberCardProps> = ({member}) => {
  return (
    <div className="column is-3">
      <a className="box member" href={member.website} target="_blank" rel="noopener noreferrer">
        <img alt={member.name} src={getThumbnailWithSize(member.logo, 'large', 'crop')}/>
      </a>
    </div>
  )
}

export default MemberCard;