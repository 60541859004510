import React, { Fragment, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { FairTradePrinciple } from '../../http/FairTradePrinciple';
import PrincipleCard from './PrincipleCard';

const PrinciplesList: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<FairTradePrinciple[]>("/items/fair_trade_principles?fields=*,icon.data");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && response.length) {
    return (
      <Fragment>
        { response.map(principle => <PrincipleCard principle={principle} />) }
      </Fragment>
    )
  }

  return <p>No items</p>
}

export default PrinciplesList;