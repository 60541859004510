import React, { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { SDGoal } from '../../http/SDGoal';

const GoalsList: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<SDGoal[]>("/items/development_goals?fields=*,image.data");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return (<p>Loading...</p>)
  }

  if (error) {
    return (<p>Loading error</p>)
  }

  if ((response && !response.length) || !response) {
    return (<p>No goals found</p>)
  }

  return (
    <div className="columns is-multiline">
      { response.map((goal) => (
        <div className="column mt48 is-half">
          <div className="columns">
            <div className="column is-5">
              <img alt={goal.title} src={goal.image.data.full_url}/>
            </div>
            <div className="column">
              <h5 className="title is-5">
                {goal.title}
              </h5>
              <p>
                {goal.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GoalsList;