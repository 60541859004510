import React, { Fragment, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { AxiosResponse } from 'axios';
import SuccessMessage from './SuccessMessage';
import ErrorMessage from './ErrorMessage';

type JoinUsFormProps = {
  isCompany: boolean;
}

const JoinUsForm: React.FC<JoinUsFormProps> = ({isCompany}) => {
  const [{response, isLoading, error}, doFetch] = useFetch<AxiosResponse<any>>("/items/join_requests");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [message, setMessage] = useState("");

  function sendRequest(e: React.MouseEvent) {
    const requestData = {
      type: isCompany ? 'company' : 'individual',
      name,
      email,
      phone,
      company_name: companyName,
      company_website: companyWebsite,
      company_size: companySize,
      message,
    }
    console.log(requestData);

    e.preventDefault();
    doFetch({
      method: 'post',
      data: requestData,
    })
  }

  if (response && response.status === 200) {
    return <SuccessMessage />
  }

  if (error || (response && response.status !== 200)) {
    return <ErrorMessage />
  }

  return (
    <form id="submit-form">
      <div className="field">
        <div className="control">
          <input 
            className="input" type="text" placeholder="Name" 
            onChange={(e) => setName(e.target.value)} 
            required={true}
          />
        </div>
        <div className="control mt24">
          <input 
            className="input" type="text" placeholder="Email"
            onChange={(e) => setEmail(e.target.value)} 
            required={true}
          />
        </div>
        <div className="control mt24">
          <input 
            className="input" type="text" placeholder="Phone"
            onChange={(e) => setPhone(e.target.value)} 
          />
        </div>
        {
          isCompany &&
          (
            <Fragment>
              <div className="control mt24">
                <input 
                  className="input" type="text" placeholder="Company name"
                  onChange={(e) => setCompanyName(e.target.value)} 
                />
              </div>
              <div className="control mt24">
                <input 
                  className="input" type="text" placeholder="Company website"
                  onChange={(e) => setCompanyWebsite(e.target.value)} 
                />
              </div>
              <div className="select mt24">
                <select defaultValue={0} onChange={(e) => setCompanySize(e.target.value)} >
                  <option disabled={true} value={0}>Company size</option>
                  <option>1-10</option>
                  <option>11-50</option>
                  <option>51-200</option>
                  <option>200+</option>
                </select>
              </div>
            </Fragment>
          )
        }
        <div className="control mt24">
          <textarea 
            className="textarea" placeholder="Include any other details about your request"
            onChange={(e) => setMessage(e.target.value)} 
          ></textarea>
        </div>
      </div>
      <div className="has-text-right">
        <button 
          className="button is-primary mt24"
          onClick={(e) => sendRequest(e)}
          disabled={isLoading}
        >
          { isLoading ? 'Please wait...' : 'Send' }
        </button>
      </div>
    </form>
  )
}

export default JoinUsForm;