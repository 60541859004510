import React from 'react';

const ErrorMessage: React.FC = () => {
  return (
    <div className="has-text-centered">
      <p>Something went wrong</p>
      <div className="columns">
        <div className="column">
          <div className="mt40">
            <div className="has-text-centered">
              <img alt="" src="/img/icons/monitoring.svg"/>
              <h6 className="subtitle is-6 mt24">Unfortunately, we were not able to receive your details. Please, contact us directly via email or phone.</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorMessage;