import React from 'react';
import { Activity } from '../../http/Activity';

type ActivityCardProps = {
  activity: Activity;
}

const ActivityCard: React.FC<ActivityCardProps> = ({activity}) => {
  return (
    <div className="column has-text-centered">
      <img alt={activity.title} src={activity.icon.data.full_url}/>
      <h5 className="title is-5 mt24">
        {activity.title}
      </h5>
      <p className="mt12">
        {activity.description}
      </p>
    </div>
  )
}

export default ActivityCard;