import React, { useEffect } from 'react';
import { Member } from "../../http/Member"
import useFetch from '../../hooks/useFetch';
import MemberCard from './MemberCard';

const MembersList: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<Member[]>("/items/members?fields=*,logo.data");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && response.length) {
    return (
      <div className="columns is-multiline is-centered">
        { response.map(member => <MemberCard member={member} />) }
      </div>
    )
  }

  return <p>No members yet</p>
}

export default MembersList;