import React, { useEffect, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { Article } from '../../http/Article';
import useFetch from '../../hooks/useFetch';

type ArticleProps = {
  slug: string;
}

const ArticleCard: React.FC<ArticleProps> = ({slug}) => {
  const [{response, isLoading, error}, doFetch] = useFetch<Article[]>(
    `/items/articles?filter[slug]=${slug}`
  );

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error article {slug}</p>
  }

  if (response && response.length) {
    return (
      <Fragment>
        <div className="has-text-centered">
          <h5 className="title is-5 has-text-primary is-uppercase">
            {response[0].title}
          </h5>
        </div>
        <div className="has-text-centered mt16">
          <h2 className="title is-2">
            {response[0].subtitle}
          </h2>
        </div>
        { response[0].content && (
          <div className="columns mt40">
            <div className="column is-8 is-offset-2">
              { ReactHtmlParser(response[0].content) }
            </div>
          </div>
        )}
      </Fragment>
    )
  }

  return <p>Article {slug} not found</p>
}

export default ArticleCard;