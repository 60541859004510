import React from 'react';

const SuccessMessage: React.FC = () => {
  return (
    <div className="mt40 has-text-centered">
      <p>Thanks! Your request was successfully submitted.</p>
      <p className="mt24">What’s next:</p>
      <div className="columns">
        <div className="column">
          <div className="mt40">
            <div className="has-text-centered"><img alt="" src="/img/icons/review.svg"/>
              <h6 className="subtitle is-6 mt24">Our board members will review your application</h6>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="mt40">
            <div className="has-text-centered"><img alt="" src="/img/icons/send.svg"/>
              <h6 className="subtitle is-6 mt24">We get back to you within a week</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessMessage;