import React, { Fragment } from 'react';
import PersonsBoard from '../components/Persons/PersonsBoard';
import PrinciplesList from '../components/FairTradePrinciples/PrinciplesList';
import ArticleCard from '../components/Article/ArticleCard';

const AboutUs: React.FC = () => {
  return (
    <Fragment>
      <section className="section is-medium has-background-blue900">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-white is-spaced">Fair Trade Software Foundation</h1>
          <h3 className="subtitle is-3 has-text-white mt32">Empowering people, enabling economies</h3>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <ArticleCard slug="goals" />
        </div>
      </section>
      <section className="section is-medium has-background-blue50">
        <div className="container">
          <ArticleCard slug="history" />
        </div>
      </section>
      <section className="section is-medium">
        <div className="container has-text-centered">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">Meet the board</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">The Fair Trade Software Foundation board</h2>
          </div>
          <PersonsBoard />
        </div>
      </section>
      <section className="section is-medium has-background-blue50">
        <div className="container">
          <ArticleCard slug="our-principles" />
          <div className="columns is-multiline is-variable is-8 is-centered">
            <PrinciplesList />
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default AboutUs;