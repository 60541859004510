import React, { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { AxiosResponse } from 'axios';
import SuccessMessage from '../JoinUsForm/SuccessMessage';
import ErrorMessage from './ErrorMessage';

const ContactUsForm: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<AxiosResponse<any>>("/items/contacts");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  function sendRequest(e: React.MouseEvent) {
    e.preventDefault();
    doFetch({
      method: 'post',
      data: {
        name,
        email,
        phone,
        company,
        message,
      }
    })
  }

  if (response && response.status === 200) {
    return <SuccessMessage />
  }

  if (error || (response && response.status !== 200)) {
    return <ErrorMessage />
  }

  return (
    <form id="submit-form">
      <div className="field">
        <div className="control">
          <input 
            className="input" type="text" placeholder="Name" 
            onChange={(e) => setName(e.target.value)} 
            required={true}
          />
        </div>
        <div className="control mt24">
          <input 
            className="input" type="email" placeholder="Email" 
            onChange={(e) => setEmail(e.target.value)} 
            required={true}
          />
        </div>
        <div className="control mt24">
          <input 
            className="input" type="text" placeholder="Phone"
            onChange={(e) => setPhone(e.target.value)} 
          />
        </div>
        <div className="control mt24">
          <input 
            className="input" type="text" placeholder="Company"
            onChange={(e) => setCompany(e.target.value)} 
          />
        </div>
        <div className="control mt24">
          <textarea 
            className="textarea" placeholder="Message"
            onChange={(e) => setMessage(e.target.value)} 
          ></textarea>
        </div>
      </div>
      <div className="has-text-right">
        <button 
          className="button is-primary mt24" 
          disabled={isLoading}
          onClick={(e) => sendRequest(e)}
        >
          { isLoading ? 'Please wait...' : 'Send' }
        </button>
      </div>
    </form>
  )
}

export default ContactUsForm;