import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import WhatWeDo from '../pages/WhatWeDo';
import OurWork from '../pages/OurWork';
import Members from '../pages/Members';
import Research from '../pages/Research';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import JoinUs from '../pages/JoinUs';
import Individual from '../pages/JoinUs/Individual';
import Europe from '../pages/JoinUs/Europe';
import Africa from '../pages/JoinUs/Africa';
import Ngo from '../pages/JoinUs/Ngo';
import NeedNgo from '../pages/JoinUs/NeedNgo';
import Policy from '../pages/Policy';
import SingleStory from '../pages/SingleStory';
import NotFound from '../pages/NotFound';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/what-we-do">
        <WhatWeDo />
      </Route>
      <Route path="/our-work" exact>
        <OurWork />
      </Route>
      <Route path="/our-work/:slug" component={SingleStory}>
      </Route>
      <Route path="/members">
        <Members />
      </Route>
      <Route path="/research">
        <Research />
      </Route>
      <Route path="/about-us">
        <AboutUs />
      </Route>
      <Route path="/contact-us">
        <ContactUs />
      </Route>
      <Route path="/join-us" exact>
        <JoinUs />
      </Route>

      <Route path="/join-us/individual">
        <Individual />
      </Route>
      <Route path="/join-us/ict-europe">
        <Europe />
      </Route>
      <Route path="/join-us/ict-africa">
        <Africa />
      </Route>
      <Route path="/join-us/ngo">
        <Ngo />
      </Route>
      <Route path="/join-us/need-ngo">
        <NeedNgo />
      </Route>

      <Route path="/policy">
        <Policy />
      </Route>

      <Route><NotFound/></Route>
    </Switch>
  )
}

export default Routes;