import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Benefits from '../components/Benefits';
import FeaturedStory from '../components/Stories/Featured';
import KeyPointsList from '../components/KeyPoints/KeyPointsList';
import HomeArticle from '../components/Article/HomeArticle';

const Home: React.FC = () => {
  return (
    <Fragment>
      <HomeArticle />
      <Benefits />
      <section className="section">
        <KeyPointsList />
      </section>
      <section className="section">
        <div className="container">
          <div className="has-text-centered">
            <h5 className="title is-5 has-text-primary is-uppercase">Get inspired</h5>
          </div>
          <div className="has-text-centered mt16">
            <h2 className="title is-2">See what our members accomplish</h2>
          </div>
          <FeaturedStory />
          <div className="has-text-centered">
            <Link className="button is-primary" to="/our-work">See all stories</Link>
          </div>
        </div>
      </section>
      <section className="section has-background-blue50 is-medium">
        <div className="container has-text-centered">
          <h2 className="title is-2">Join us and contribute</h2>
          <p>See how you or your company can play a role in the Foundation. By working together we can strengthen ICT capability and capacity<br/> in developing countries whilst delivering real benefits for all members.</p>
          <Link className="button is-primary mt40" to="/join-us">Become a member</Link>
        </div>
      </section>
    </Fragment>
  );
}

export default Home;