import React from 'react';
import { FairTradePrinciple } from '../../http/FairTradePrinciple';

type PrincipleCardProps = {
  principle: FairTradePrinciple;
}

const PrincipleCard: React.FC<PrincipleCardProps> = ({ principle }) => {
  return (
    <div className="column is-4 principle has-text-centered mt80">
      <div className="icon">
        <img alt={principle.title} src={principle.icon.data.full_url}/>
      </div>
      <h5 className="title is-5 mt24">
        {principle.title}
      </h5>
      <p className="mt12">
        {principle.description}
      </p>
    </div>
  )
}

export default PrincipleCard;