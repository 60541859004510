import React, { Fragment, useEffect } from 'react';
import { KeyPoint } from '../../http/KeyPoint';
import useFetch from '../../hooks/useFetch';

type KeyPointProps = {
  keyPoint: KeyPoint;
}

const MobileKeyPoint: React.FC<KeyPointProps> = ({keyPoint}) => {
  return (
    <Fragment>
      <img className="mt48" src={keyPoint.image.data.full_url} alt={keyPoint.title}/>
      <h5 className="title is-5 mt32">
        {keyPoint.title}
      </h5>
      <p className="mt16">
        {keyPoint.description}
      </p>
    </Fragment>
  )
}

const LeftKeyPoint: React.FC<KeyPointProps> = ({keyPoint}) => {
  return (
    <div className="columns is-vcentered">
      <div className="column is-6 is-offset-1">
        <img src={keyPoint.image.data.full_url} alt={keyPoint.title}/>
      </div>
      <div className="column is-5" style={{marginLeft: "-100px"}}>
        <div className="box">
          <h3 className="title is-3">
            {keyPoint.title}
          </h3>
          <p>
            {keyPoint.description}
          </p>
        </div>
      </div>
    </div>
  )
}

const RightKeyPoint: React.FC<KeyPointProps> = ({keyPoint}) => {
  return (
    <div className="columns is-vcentered">
      <div className="column is-5 is-offset-1" style={{zIndex: 2}}>
        <div className="box">
        <h3 className="title is-3">
            {keyPoint.title}
          </h3>
          <p>
            {keyPoint.description}
          </p>
        </div>
      </div>
      <div className="column is-6" style={{marginLeft: "-50px"}}>
        <img src={keyPoint.image.data.full_url} alt={keyPoint.title}/>
      </div>
    </div>
  )
}

function isOdd(index: number) {
  return index % 2 === 1;
}

const KeyPointsList: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<KeyPoint[]>("/items/key_points?fields=*,image.data");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && response.length) {
    return (
      <Fragment>
        <div className="container is-hidden-desktop">
          { response.map(keyPoint => <MobileKeyPoint key={keyPoint.id} keyPoint={keyPoint} />) }
        </div>
        <div className="container is-hidden-touch">
          { 
            response.map(
              (keyPoint, index) => isOdd(index) 
                ? <RightKeyPoint key={index} keyPoint={keyPoint} /> 
                : <LeftKeyPoint key={index} keyPoint={keyPoint} /> 
            ) 
          }
        </div>
      </Fragment>
    )
  }

  return <p>No key points found</p>
}

export default KeyPointsList;