import React, { Fragment, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { Person } from '../../http/Person';
import PersonCard from './PersonCard';

function isDirector(person: Person): boolean {
  return person.is_director;
}

function isAdvisory(person: Person): boolean {
  return !isDirector(person);
}

const PersonsBoard: React.FC = () => {
  const [{response, isLoading, error}, doFetch] = useFetch<Person[]>("/items/persons?fields=*,photo.data");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Loading error</p>
  }

  if (response && response.length) {
    return (
      <Fragment>
        <h3 className="title is-3 mt40">Board of Directors</h3>
        <div className="board">
          { response.filter(isDirector).map(person => <PersonCard key={person.id} person={person} />) }
        </div>

        <h3 className="title is-3 mt48">Advisory Board</h3>
        <div className="board">
          { response.filter(isAdvisory).map(person => <PersonCard key={person.id} person={person} />) }
        </div>
      </Fragment>
    )
  }

  return <p>No board members</p>
}

export default PersonsBoard;